export const SERVICE_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment ServiceFields on Service {
    id
    route {
      id
      from {
        id
        name
      }
      to {
        id
        name
      }
    }
    operator {
      id
      name
      logo
    }
    disabled
    price
    depart
    arrive
    capacity
    available
    isPrivate
    mustBookAll

    code
    name
    cb
    sb
    co
    so
  }
`;
export const GET_WEBSITE_SERVICE = /* GraphQL */ `
  ${SERVICE_FIELDS_FRAGMENT}
  query GetWebsiteService($input: GetServiceInput!) {
    getWebsiteService(input: $input) {
      outbound {
        ...ServiceFields
      }
      inbound {
        ...ServiceFields
      }
    }
  }
`;
export const GET_WEBSITE_SERVICES = /* GraphQL */ `
  ${SERVICE_FIELDS_FRAGMENT}
  query GetWebsiteServices($input: GetWebsiteServicesInput!) {
    getWebsiteServices(input: $input) {
      data {
        outbound {
          ...ServiceFields
        }
        inbound {
          ...ServiceFields
        }
      }
      nextToken
    }
  }
`;
export const GET_WEBSITE_RETRUN_SERVICES = /* GraphQL */ `
  ${SERVICE_FIELDS_FRAGMENT}
  query GetWebsiteReturnServices($input: GetByServiceRouteAndDateInput!) {
    getWebsiteReturnServices(input: $input) {
      data {
        outbound {
          ...ServiceFields
        }
        inbound {
          ...ServiceFields
        }
      }
      nextToken
    }
  }
`;
export const REQUEST_SERVICE = /* GraphQL */ `
  mutation RequestService($input: CreateServiceRequestInput!) {
    requestService(input: $input) {
      id
    }
  }
`;
